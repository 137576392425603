<template>
    <v-app style="background-color: #f7f7f7;">
        <v-container fluid class="pa-0">
            <!-- logo 图标 -->
            <div class="logo py-6 px-7">
              <v-row class="d-flex align-center" style="text-align:center">
                <v-img src="../../public/logo.png" max-height="55" max-width="55" class=""></v-img>
                <span class="text-h5"><strong>五洲GNSS平台</strong></span>
              </v-row>
            </div>
            <!-- 主页按钮 -->
            <div class="main-button">
              <v-btn @click="toHome()"  color="primary">
                <v-icon left class="">mdi-home</v-icon>
                <strong>主页</strong>
              </v-btn>
            </div>
            <v-row class="px-10" style="margin-top:80px" >
                <v-col cols="4">
                    <v-card style="background-color: #fff;" max-width="600">
                      <div class="">
                        <v-img src="../../public/beidou.jpg" max-width="540" max-height="350" class="mx-auto"></v-img>
                      </div>
                      <v-card-subtitle class="font-weight-bold">
                        <div class="body-1 font-weight-bold">北斗产业化</div>
                        <div>风起潮涌</div>
                      </v-card-subtitle>
                      <v-card-text>
                        <div>北斗三号卫星的全球组网，让北斗千亿产业链蓄势待发。而随着底层软硬件技术的逐渐完善，高精度定位市场也愈发火热。</div>
                        <div class="mt-4">相关机构表示，预计到2025年，北斗将带动形成8千－1万亿元规模的时空信息服务市场。同时，国家“十四五”规划确定的“北斗产业化”重大工程已经启动。
                          各政府部门也明确提出推动高精度地图与定位等核心技术和产品的发展。</div>
                        <div class="mt-4">作为北斗产业化重要落地方向的高精度定位技术，已进入大规模应用时代。</div>
                      </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="4">
                    <v-card style="background-color: #fff;" max-width="600">
                      <div class="">
                          <v-img src="../../public/fuwu.jpg" max-width="540" max-height="350" class="mx-auto"></v-img>
                      </div>
                      <v-card-subtitle class="font-weight-bold">
                        <div class="body-1 font-weight-bold">服务精细化</div>
                        <div>客户至上</div>
                      </v-card-subtitle>
                      <v-card-text>
                        <div>在高精度定位技术迅猛发展的今天，位置服务竞争的核心，更多是对客户不同应用场景洞若观火的理解。
                          力求一切资源配置都要从用户需求出发，创造真正的用户价值。</div>
                        <div class="mt-4">提供定制化服务，基于用户需求，规划CORS站点，定制专属解决方案和企业级运营平台，充分满足不同行业、不同场景客户的不同需求。</div>
                        <div class="mt-4">打造一站式选购，与北斗芯片、模组、终端等产业链上下游厂商，合力打造高精度定位服务闭环，持续深化高精度定位服务的落地应用。</div>
                      </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="4">
                    <v-card style="background-color: #fff;" max-width="600">
                      <v-card-title class="d-flex justify-center">
                        <div>相关产品</div>
                      </v-card-title>
                      <v-card-text>
                        <v-row class="text-center" v-for="(row,index) in 2" :key="index">
                          <v-col cols="4" v-for="(col,index) in 3" :key="index">
                            <v-img src="../../public/product.png" max-width="200" max-height="200"></v-img>
                            <div>ZED-F9P 模块</div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            
        </v-container>
    </v-app>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        toHome() {
            this.$router.push("/")
        }
    }
}
</script>
<style scoped>
.logo {
  position: absolute;
  top: 4px;
  left: 10px;
  z-index: 100;
  /* background: white;
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  background-clip: padding-box; */
}
.main-button {
  position: absolute;
  right: 11px;
  top: 13px;
}
</style>